// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as IconSelect from "../../../../styleguide/icons/IconSelect.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectProposalsScss from "./UserProjectProposals.scss";

var css = UserProjectProposalsScss;

function UserProjectProposals_Filter_Sorting(props) {
  var update = props.update;
  var filters = props.filters;
  var sortBy = filters.sortBy;
  var tmp;
  if (sortBy !== undefined) {
    var variant = sortBy.NAME;
    tmp = variant === "Alphabetical" ? (
        sortBy.VAL === "Desc" ? "Provider Name (Z-A)" : "Provider Name (A-Z)"
      ) : (
        variant === "CreatedDate" ? (
            sortBy.VAL === "Desc" ? "Created last first" : "Created soon first"
          ) : (
            sortBy.VAL === "Desc" ? "Expires last first" : "Expires soon first"
          )
      );
  } else {
    tmp = "Sort by";
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(Popover.make, {
                    children: [
                      JsxRuntime.jsxs(Popover.Trigger.make, {
                            className: css.popoverTrigger,
                            children: [
                              JsxRuntime.jsx(Control.AsLink.make, {
                                    color: "Gray",
                                    onClick: (function (param) {
                                        
                                      }),
                                    children: tmp
                                  }),
                              JsxRuntime.jsx(IconSelect.make, {
                                    size: "XXXS",
                                    color: "GrayText"
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Popover.Body.make, {
                            position: {
                              TAG: "Below",
                              _0: "RightEdge"
                            },
                            className: css.popoverBody,
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("div", {
                                          children: "Created soon first",
                                          className: css.sortSelection,
                                          onClick: (function (param) {
                                              update({
                                                    providerName: filters.providerName,
                                                    statuses: filters.statuses,
                                                    sortBy: {
                                                      NAME: "CreatedDate",
                                                      VAL: "Asc"
                                                    }
                                                  });
                                            })
                                        }),
                                    className: css.popoverRow
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("div", {
                                          children: "Created last first",
                                          className: css.sortSelection,
                                          onClick: (function (param) {
                                              update({
                                                    providerName: filters.providerName,
                                                    statuses: filters.statuses,
                                                    sortBy: {
                                                      NAME: "CreatedDate",
                                                      VAL: "Desc"
                                                    }
                                                  });
                                            })
                                        }),
                                    className: css.popoverRow
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("div", {
                                          children: "Provider Name (A-Z)",
                                          className: css.sortSelection,
                                          onClick: (function (param) {
                                              update({
                                                    providerName: filters.providerName,
                                                    statuses: filters.statuses,
                                                    sortBy: {
                                                      NAME: "Alphabetical",
                                                      VAL: "Asc"
                                                    }
                                                  });
                                            })
                                        }),
                                    className: css.popoverRow
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("div", {
                                          children: "Provider Name (Z-A)",
                                          className: css.sortSelection,
                                          onClick: (function (param) {
                                              update({
                                                    providerName: filters.providerName,
                                                    statuses: filters.statuses,
                                                    sortBy: {
                                                      NAME: "Alphabetical",
                                                      VAL: "Desc"
                                                    }
                                                  });
                                            })
                                        }),
                                    className: css.popoverRow
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("div", {
                                          children: "Expires soon first",
                                          className: css.sortSelection,
                                          onClick: (function (param) {
                                              update({
                                                    providerName: filters.providerName,
                                                    statuses: filters.statuses,
                                                    sortBy: {
                                                      NAME: "ExpiresDate",
                                                      VAL: "Asc"
                                                    }
                                                  });
                                            })
                                        }),
                                    className: css.popoverRow
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("div", {
                                          children: "Expires last first",
                                          className: css.sortSelection,
                                          onClick: (function (param) {
                                              update({
                                                    providerName: filters.providerName,
                                                    statuses: filters.statuses,
                                                    sortBy: {
                                                      NAME: "ExpiresDate",
                                                      VAL: "Desc"
                                                    }
                                                  });
                                            })
                                        }),
                                    className: css.popoverRow
                                  })
                            ]
                          })
                    ]
                  }),
              className: css.sorting
            });
}

var make = UserProjectProposals_Filter_Sorting;

export {
  css ,
  make ,
}
/* css Not a pure module */
