// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../api/Api.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Button from "../../components/Button/Button.res.js";
import * as Control from "../../components/Control/Control.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Textarea from "../../forms/Textarea/Textarea.res.js";
import * as IconClose from "../../icons/IconClose.res.js";
import * as DatePicker from "../../forms/DatePicker/DatePicker.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as Routes_Project from "../../../routes/common/Routes_Project.res.js";
import * as ProjectProposal from "../../../models/ProjectProposal.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as TourRequestModalScss from "./TourRequestModal.scss";

var css = TourRequestModalScss;

function TourRequestModal(props) {
  var proposalId = props.proposalId;
  var projectId = props.projectId;
  var close = props.close;
  var initialState = React.useMemo((function () {
          return {
                  input: ProjectProposal.TourRequest.empty(),
                  proposalId: proposalId
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            return {
                    TAG: "Update",
                    _0: {
                      input: ProjectProposal.TourRequest.empty(),
                      proposalId: state.proposalId
                    }
                  };
          } else {
            return {
                    TAG: "Update",
                    _0: {
                      input: action._0,
                      proposalId: state.proposalId
                    }
                  };
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var sendTourRequest = function () {
    $$Promise.wait(Api.proposalTourRequest(projectId, state.proposalId, state.input), (function (x) {
            if (x.TAG === "Ok") {
              return Url.visit(Routes_Project.Proposal.show(projectId, x._0.id));
            } else {
              return SentryLogger.error1({
                          rootModule: "TourRequestModal",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "TourRequestModal.make"
                        }, "TourRequestModal::ProposalTourRequest::Error", [
                          "Error",
                          x._0
                        ]);
            }
          }));
    dispatch("Send");
    close();
  };
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(Control.make, {
                            className: css.closeOverlayButton,
                            onClick: (function (param) {
                                close();
                              }),
                            children: JsxRuntime.jsx(IconClose.make, {
                                  title: "Close",
                                  size: "MD",
                                  color: "Gray"
                                })
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "Request Tour",
                                    className: css.tourRequestModalMessageText
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: "Send a request to the provider if you want to have a tour in the facility.",
                                    className: css.tourRequestModalParagraph
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("h6", {
                                            children: "Date of the Tour",
                                            className: css.tourRequestLabel
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(DatePicker.make, {
                                                  id: "tour-request-date-picker",
                                                  value: Belt_Option.map(state.input.tourDate, (function (prim) {
                                                          return prim;
                                                        })),
                                                  disabledDays: [{
                                                      from: new Date(0, 0),
                                                      to: new Date()
                                                    }],
                                                  onDayChange: (function (value, param, param$1) {
                                                      var init = state.input;
                                                      dispatch({
                                                            TAG: "Update",
                                                            _0: {
                                                              message: init.message,
                                                              tourDate: Caml_option.some(value)
                                                            }
                                                          });
                                                    }),
                                                  className: css.tourDatePicker
                                                }),
                                            className: css.tourDatePickerContainer
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("h6", {
                                                    children: "Message",
                                                    className: css.tourRequestLabel
                                                  }),
                                              JsxRuntime.jsx(Textarea.make, {
                                                    id: "tour-request-message",
                                                    value: state.input.message,
                                                    placeholder: "Tell the provider what you want to see and other details, also specify how many attendees will be participating",
                                                    className: css.tourDateMessage,
                                                    onChange: (function ($$event) {
                                                        var init = state.input;
                                                        dispatch({
                                                              TAG: "Update",
                                                              _0: {
                                                                message: $$event.target.value,
                                                                tourDate: init.tourDate
                                                              }
                                                            });
                                                      })
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(Button.make, {
                                                            size: "SM",
                                                            color: "Teal",
                                                            disabled: state.input.message === "" || state.input.tourDate === undefined,
                                                            visuallyDisabled: state.input.message === "" || state.input.tourDate === undefined,
                                                            className: css.sendButton,
                                                            onClick: (function (param) {
                                                                sendTourRequest();
                                                              }),
                                                            children: "Send Request"
                                                          }),
                                                      JsxRuntime.jsx(Button.make, {
                                                            size: "SM",
                                                            color: "Gray",
                                                            className: css.cancelButton,
                                                            onClick: (function (param) {
                                                                close();
                                                              }),
                                                            children: "Cancel"
                                                          })
                                                    ],
                                                    className: css.buttons
                                                  })
                                            ],
                                            className: css.messageContainer
                                          })
                                    ],
                                    className: css.tourDateSelectionContainer
                                  })
                            ],
                            className: css.tourRequestModalInformation
                          })
                    ],
                    className: css.tourRequestModalContainer
                  }),
              className: css.tourRequestModalMessageOverlay
            });
}

var make = TourRequestModal;

export {
  css ,
  make ,
}
/* css Not a pure module */
