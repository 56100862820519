// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as $$Date from "../../../../libs/Date.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as Project from "../../../../models/Project.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconFile from "../../../../styleguide/icons/IconFile.res.js";
import * as IconDelete from "../../../../styleguide/icons/IconDelete.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as IconFinalized from "../../../../styleguide/icons/IconFinalized.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as TourRequestModal from "../../../../styleguide/modals/TourRequestModal/TourRequestModal.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as TourRequestSentModal from "../../../../styleguide/modals/TourRequestModal/TourRequestSentModal.res.js";
import * as ProjectProposalStatusDropdown from "../_components/ProjectProposalStatusDropdown/ProjectProposalStatusDropdown.res.js";
import * as UserProjectProposals_TileScss from "./UserProjectProposals_Tile.scss";

var css = UserProjectProposals_TileScss;

function UserProjectProposals_Tile(props) {
  var projectId = props.projectId;
  var userViewContext = props.userViewContext;
  var role = props.role;
  var proposal = props.proposal;
  var match = React.useState(function () {
        return "Hidden";
      });
  var setDeleteModalVisibility = match[1];
  var match$1 = React.useState(function () {
        return "Hidden";
      });
  var setTourRequestModalVisibility = match$1[1];
  var match$2 = proposal.status;
  var tourButton = JsxRuntime.jsx(Button.make, {
        size: "SM",
        color: "Gray",
        visuallyDisabled: !(match$2 === "Preparing" || match$2 === "InProgress"),
        buttonClassName: proposal.status === "Preparing" ? css.tourButtonPreparing : css.tourButton,
        onClick: (function (param) {
            setTourRequestModalVisibility(function (param) {
                  return "Shown";
                });
          }),
        children: JsxRuntime.jsx("span", {
              children: "Request Tour"
            })
      });
  var deleteProposal = function () {
    $$Promise.wait(Api.deleteProjectProposal(projectId, proposal.id), (function (x) {
            if (x.TAG !== "Ok") {
              return SentryLogger.error1({
                          rootModule: "UserProjectProposals_Tile",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "UserProjectProposals_Tile.make"
                        }, "DeleteProposal::Error", [
                          "ProposalId",
                          proposal.id
                        ]);
            }
            window.location.reload();
          }));
  };
  var category = Project.Category.fromString(proposal.projectCategory, proposal.projectSubcategory);
  var match$3 = proposal.status;
  var tmp;
  if (match$3 === "Finalized" || match$3 === "NoBid" || match$3 === "Canceled" || match$3 === "Expired") {
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("p", {
                  children: proposal.description,
                  className: css.providerDesc
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsxs(Button.make, {
                          size: "SM",
                          color: "Primary",
                          disabled: true,
                          visuallyDisabled: true,
                          children: [
                            JsxRuntime.jsx(IconFile.make, {
                                  size: "SM",
                                  color: "White",
                                  className: css.proposalSvg
                                }),
                            "Download Proposal"
                          ]
                        }),
                    tourButton
                  ],
                  className: css.buttonContainer
                })
          ]
        });
  } else if (match$3 === "Preparing") {
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("p", {
                  children: proposal.statusMessage,
                  className: css.providerDesc
                }),
            tourButton
          ]
        });
  } else {
    var tmp$1;
    if (category.TAG === "Colocation") {
      if (proposal.tourRequested) {
        tmp$1 = JsxRuntime.jsxs(Button.make, {
              size: "SM",
              color: "Gray",
              buttonClassName: proposal.status === "Preparing" ? css.tourSentButtonPreparing : css.tourSentButton,
              onClick: (function (param) {
                  setTourRequestModalVisibility(function (param) {
                        return "Shown";
                      });
                }),
              children: [
                JsxRuntime.jsx(IconFinalized.make, {
                      size: "SM",
                      color: "White",
                      className: css.proposalSvg
                    }),
                "Tour Requested"
              ]
            });
      } else {
        switch (userViewContext) {
          case "Personal" :
              switch (role) {
                case "Provider" :
                case "Visitor" :
                    tmp$1 = null;
                    break;
                default:
                  tmp$1 = tourButton;
              }
              break;
          case "Admin" :
              switch (role) {
                case "Provider" :
                case "Visitor" :
                    tmp$1 = null;
                    break;
                default:
                  tmp$1 = tourButton;
              }
              break;
          case "Visitor" :
              tmp$1 = null;
              break;
          
        }
      }
    } else {
      tmp$1 = null;
    }
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("p", {
                  children: proposal.description,
                  className: css.providerDesc
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsxs(Popover.make, {
                          className: css.downloadButton,
                          children: [
                            JsxRuntime.jsx(Popover.Trigger.make, {
                                  className: css.downloadButton,
                                  children: JsxRuntime.jsxs(Button.make, {
                                        size: "SM",
                                        color: "Primary",
                                        children: [
                                          JsxRuntime.jsx(IconFile.make, {
                                                size: "SM",
                                                color: "White",
                                                className: css.proposalSvg
                                              }),
                                          "Download Proposal"
                                        ]
                                      })
                                }),
                            JsxRuntime.jsxs(Dropdown.Body.make, {
                                  position: {
                                    TAG: "Below",
                                    _0: "RightEdge"
                                  },
                                  className: css.newProjectMenu,
                                  children: [
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(Control.make, {
                                                className: css.newProjectMenuItemControl,
                                                onClick: (function (param) {
                                                    Url.visit(Routes_Project.Proposal.download(projectId, proposal.id));
                                                  }),
                                                children: "Download Current Proposal"
                                              }),
                                          className: css.newProjectMenuItem
                                        }),
                                    proposal.additionalDocuments.length !== 0 ? JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(Control.make, {
                                                  className: css.newProjectMenuItemControl,
                                                  onClick: (function (param) {
                                                      Url.visit(Routes_Project.Proposal.downloadAllAdditional(projectId, proposal.id));
                                                    }),
                                                  children: "Download Proposal & Documents"
                                                }),
                                            className: css.newProjectMenuItem
                                          }) : null,
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(Control.make, {
                                                className: css.newProjectMenuItemControl,
                                                onClick: (function (param) {
                                                    Url.visit(Routes_Project.Proposal.downloadAll(projectId, proposal.id));
                                                  }),
                                                children: "Download All"
                                              }),
                                          className: css.newProjectMenuItem
                                        })
                                  ]
                                })
                          ]
                        }),
                    tmp$1
                  ],
                  className: css.buttonContainer
                })
          ]
        });
  }
  var tmp$2;
  tmp$2 = category.TAG === "Colocation" && match$1[0] === "Shown" ? (
      proposal.tourRequested ? JsxRuntime.jsx(TourRequestSentModal.make, {
              close: (function () {
                  setTourRequestModalVisibility(function (param) {
                        return "Hidden";
                      });
                }),
              providerName: proposal.providerName,
              tourDate: proposal.tourDate,
              message: proposal.statusMessage
            }) : JsxRuntime.jsx(TourRequestModal.make, {
              close: (function () {
                  setTourRequestModalVisibility(function (param) {
                        return "Hidden";
                      });
                }),
              projectId: projectId,
              proposalId: proposal.id
            })
    ) : null;
  var match$4 = proposal.status;
  var tmp$3;
  if (match$4 === "Preparing") {
    tmp$3 = null;
  } else {
    var tmp$4;
    switch (role) {
      case "Provider" :
      case "User" :
      case "Visitor" :
          tmp$4 = null;
          break;
      default:
        tmp$4 = JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(Control.make, {
                    id: "deleteProposal",
                    onClick: (function (param) {
                        setDeleteModalVisibility(function (param) {
                              return "Shown";
                            });
                      }),
                    children: [
                      JsxRuntime.jsx(IconDelete.make, {
                            size: "LG",
                            color: "GrayText",
                            viewBoxSize: "20"
                          }),
                      JsxRuntime.jsx("span", {
                            children: "Delete"
                          })
                    ]
                  }),
              className: css.deleteProposal
            });
    }
    tmp$3 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx("p", {
                                  children: "Sent",
                                  className: css.dateLabel
                                }),
                            JsxRuntime.jsx("p", {
                                  children: $$Date.Naive.format(proposal.sentAt, "dd MMM yyyy"),
                                  className: css.dateValue
                                })
                          ],
                          className: css.labelValuePair
                        }),
                    JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx("p", {
                                  children: "Expires ",
                                  className: css.dateLabel
                                }),
                            JsxRuntime.jsxs("p", {
                                  children: [
                                    JsxRuntime.jsx("span", {
                                          children: $$Date.Naive.format(proposal.expiresAt, "dd MMM yyyy")
                                        }),
                                    proposal.almostExpired ? JsxRuntime.jsx("span", {
                                            children: "Soon!",
                                            className: css.soonBadge
                                          }) : null
                                  ],
                                  className: Cx.cx([
                                        css.dateValue,
                                        css.expireDate
                                      ])
                                })
                          ],
                          className: css.labelValuePair
                        })
                  ],
                  className: css.proposalDates
                }),
            tmp$4
          ]
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("img", {
                                    className: css.logo,
                                    src: proposal.providerLogo
                                  }),
                              className: css.logoContainer
                            }),
                        JsxRuntime.jsx("p", {
                              children: JsxRuntime.jsx(A.make, {
                                    id: "proposalLink",
                                    href: Routes_Project.Proposal.show(projectId, proposal.id),
                                    children: proposal.providerName
                                  }),
                              className: css.providerName
                            }),
                        tmp,
                        tmp$2
                      ],
                      className: css.left
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(ProjectProposalStatusDropdown.make, {
                              proposal: proposal,
                              projectId: projectId,
                              userViewContext: userViewContext,
                              iconSize: "XXS",
                              updateProposalStatus: props.updateProposalStatus,
                              setBidModalVisibility: (function (prim) {
                                  
                                })
                            }),
                        tmp$3
                      ],
                      className: css.right
                    }),
                match[0] === "Shown" ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Are you sure?",
                                      className: css.proposalModalMessageText
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Button.make, {
                                              id: "cancelDeleteProposal",
                                              size: "SM",
                                              color: "Gray",
                                              onClick: (function (param) {
                                                  setDeleteModalVisibility(function (param) {
                                                        return "Hidden";
                                                      });
                                                }),
                                              children: "Cancel"
                                            }),
                                        JsxRuntime.jsx(Button.make, {
                                              id: "confirmDeleteProposal",
                                              size: "SM",
                                              color: "Pink",
                                              onClick: (function (param) {
                                                  deleteProposal();
                                                }),
                                              children: "Yes, delete it"
                                            })
                                      ],
                                      className: css.proposalModalMessageButtons
                                    })
                              ],
                              className: css.proposalModalMessageContainer
                            }),
                        className: css.proposalModalMessageOverlay
                      }) : null
              ],
              className: css.container
            });
}

var make = UserProjectProposals_Tile;

export {
  css ,
  make ,
}
/* css Not a pure module */
