// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../../../libs/Date.res.js";
import * as Button from "../../components/Button/Button.res.js";
import * as Control from "../../components/Control/Control.res.js";
import * as IconClose from "../../icons/IconClose.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as TourRequestSentModalScss from "./TourRequestSentModal.scss";

var css = TourRequestSentModalScss;

function TourRequestSentModal(props) {
  var tourDate = props.tourDate;
  var close = props.close;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(Control.make, {
                            className: css.closeOverlayButton,
                            onClick: (function (param) {
                                close();
                              }),
                            children: JsxRuntime.jsx(IconClose.make, {
                                  title: "Close",
                                  size: "MD",
                                  color: "Gray"
                                })
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "Tour Information",
                                    className: css.tourRequestSentModalMessageText
                                  }),
                              JsxRuntime.jsxs("p", {
                                    children: [
                                      "Here is a summary of the tour you requested for ",
                                      props.providerName,
                                      " proposal."
                                    ],
                                    className: css.tourRequestSentModalParagraph
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("h6", {
                                            children: "Date of the Tour",
                                            className: css.tourRequestSentLabel
                                          }),
                                      JsxRuntime.jsx("p", {
                                            children: tourDate !== undefined ? $$Date.Naive.format(Caml_option.valFromOption(tourDate), "MMM dd, yyyy") : "",
                                            className: css.tourRequestSentModalParagraph
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("h6", {
                                                    children: "Message",
                                                    className: css.tourRequestSentLabel
                                                  }),
                                              JsxRuntime.jsx("p", {
                                                    children: props.message,
                                                    className: css.tourRequestSentModalMessage
                                                  }),
                                              JsxRuntime.jsx(Button.make, {
                                                    size: "SM",
                                                    color: "Gray",
                                                    className: css.cancelButton,
                                                    onClick: (function (param) {
                                                        close();
                                                      }),
                                                    children: "Cancel"
                                                  })
                                            ],
                                            className: css.messageContainer
                                          })
                                    ],
                                    className: css.tourDateSentInfoContainer
                                  })
                            ],
                            className: css.tourRequestSentModalInformation
                          })
                    ],
                    className: css.tourRequestSentModalContainer
                  }),
              className: css.tourRequestSentModalMessageOverlay
            });
}

var make = TourRequestSentModal;

export {
  css ,
  make ,
}
/* css Not a pure module */
