// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconProposal(props) {
  var color = props.color;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Proposal";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsxs("g", {
                    children: [
                      JsxRuntime.jsx("rect", {
                            height: "16",
                            width: "16",
                            x: "0",
                            y: "0"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M2.4,0.8 L2.4,15.2 L13.6,15.2 L13.6,1.92683029 L12.4948395,0.8 L2.4,0.8 Z M2.4,0 L12.4948395,0 C12.7097054,0 12.9155392,0.0864321208 13.0659901,0.239833066 L14.1711506,1.36666336 C14.3178325,1.51622138 14.4,1.71734715 14.4,1.92683029 L14.4,15.2 C14.4,15.6418278 14.0418278,16 13.6,16 L2.4,16 C1.9581722,16 1.6,15.6418278 1.6,15.2 L1.6,0.8 C1.6,0.3581722 1.9581722,0 2.4,0 Z",
                            fill: Icon.mapColor("DarkGray"),
                            fillRule: "nonzero"
                          }),
                      JsxRuntime.jsx("rect", {
                            height: "1",
                            width: "6.4",
                            fill: Icon.mapColor(color),
                            fillRule: "nonzero",
                            rx: "0.5",
                            x: "4.8",
                            y: "8.8"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M5.70342482,5.19025818 C5.53490246,5.19418022 5.37747546,5.1065172 5.2920617,4.96119114 C5.20664796,4.81586508 5.20664796,4.63567612 5.2920617,4.49035006 C5.37747546,4.345024 5.53490246,4.25736098 5.70342482,4.26128302 L6.79037356,4.26128302 C6.90457924,4.25734782 6.9951279,4.16362538 6.9951279,4.04935192 C6.9951279,3.93507844 6.90457924,3.84135602 6.79037356,3.8374208 L6.22438544,3.8374208 L6.22438544,3.40475434 C6.22045024,3.29054866 6.1267278,3.2 6.01245434,3.2 C5.89818088,3.2 5.80445844,3.29054866 5.80052324,3.40475434 L5.80052324,3.8374208 L5.70342482,3.8374208 C5.3824851,3.83202 5.08358612,4.00014738 4.9215369,4.27722414 C4.7594877,4.55430092 4.7594877,4.89724028 4.9215369,5.17431706 C5.08358612,5.45139382 5.3824851,5.6195212 5.70342482,5.6141204 L6.319723,5.6141204 C6.48824536,5.61019834 6.64567236,5.69786138 6.73108612,5.84318744 C6.81649986,5.98851348 6.81649986,6.16870246 6.73108612,6.31402852 C6.64567236,6.45935456 6.48824536,6.5470176 6.319723,6.54309556 L5.2129018,6.54309556 C5.0986961,6.54703076 5.00814746,6.6407532 5.00814746,6.75502666 C5.00814746,6.86930012 5.0986961,6.96302256 5.2129018,6.96695776 L5.79926548,6.96695776 L5.79926548,7.4084285 C5.80320068,7.52263418 5.89692312,7.61318284 6.01119658,7.61318284 C6.12547006,7.61318284 6.21919248,7.52263418 6.2231277,7.4084285 L6.2231277,6.96720932 L6.3307912,6.96720932 C6.81591584,6.95668662 7.20286936,6.55888962 7.19999997,6.07365948 C7.19709854,5.58842932 6.80544156,5.19526232 6.3202261,5.19050974 L5.70342482,5.19025818 Z",
                            fill: Icon.mapColor(color),
                            fillRule: "nonzero"
                          }),
                      JsxRuntime.jsx("rect", {
                            height: "1",
                            width: "6.4",
                            fill: Icon.mapColor(color),
                            fillRule: "nonzero",
                            rx: "0.5",
                            x: "4.8",
                            y: "10.4"
                          }),
                      JsxRuntime.jsx("rect", {
                            height: "1",
                            width: "4",
                            fill: Icon.mapColor(color),
                            fillRule: "nonzero",
                            rx: "0.5",
                            x: "4.8",
                            y: "12"
                          })
                    ],
                    fill: "none",
                    fillRule: "evenodd",
                    stroke: "none",
                    strokeWidth: "1"
                  })
            });
}

var make = IconProposal;

export {
  make ,
}
/* Icon Not a pure module */
