// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SearchField from "../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectProposalsScss from "./UserProjectProposals.scss";

var css = UserProjectProposalsScss;

function UserProjectProposals_Input(props) {
  var update = props.update;
  var filters = props.filters;
  var providerName = filters.providerName;
  return JsxRuntime.jsx(SearchField.make, {
              id: "user-project-proposals-filter--address",
              value: providerName !== undefined ? providerName : "",
              placeholder: "Search by provider",
              inputClassName: css.searchFieldInput,
              iconClassName: css.searchIcon,
              onChange: (function ($$event) {
                  update({
                        providerName: $$event.target.value,
                        statuses: filters.statuses,
                        sortBy: filters.sortBy
                      });
                })
            });
}

var make = UserProjectProposals_Input;

export {
  css ,
  make ,
}
/* css Not a pure module */
