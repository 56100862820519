// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ProjectProposal from "../../../../models/ProjectProposal.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectProposalsScss from "./UserProjectProposals.scss";

var css = UserProjectProposalsScss;

var status = [
  "InProgress",
  "Finalized",
  "Canceled",
  "Expired",
  "NoBid",
  "Preparing"
];

function UserProjectProposals_Filter_Status(props) {
  var update = props.update;
  var filters = props.filters;
  var statuses = filters.statuses;
  var tmp;
  if (statuses !== undefined) {
    var x = statuses.length;
    tmp = x !== 0 ? (
        x !== 1 ? String(x) + " statuses" : "1 status"
      ) : "All statuses";
  } else {
    tmp = "All statuses";
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(Dropdown.make, {
                    children: [
                      JsxRuntime.jsx(Dropdown.Trigger.make, {
                            className: css.dropdownTrigger,
                            iconSize: "XS",
                            iconColor: "Gray",
                            children: tmp
                          }),
                      JsxRuntime.jsxs(Dropdown.Body.make, {
                            position: {
                              TAG: "Below",
                              _0: "LeftEdge"
                            },
                            className: css.dropdownBody,
                            withButton: true,
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "All Statuses",
                                    className: css.dropdownBodyHeading
                                  }),
                              Belt_Array.mapWithIndex(status, (function (i, item) {
                                      var statuses = filters.statuses;
                                      return JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx(Checkbox.make, {
                                                          id: String(i),
                                                          size: "SM",
                                                          checked: statuses !== undefined ? (function (__x) {
                                                                  return Js_array.findIndex((function (value) {
                                                                                return value === item;
                                                                              }), __x);
                                                                })(statuses) > -1 : false,
                                                          onChange: (function ($$event) {
                                                              var checked = $$event.target.checked;
                                                              var statuses = filters.statuses;
                                                              var statuses$1 = statuses !== undefined ? (
                                                                  checked ? Belt_Array.concat(statuses, [item]) : Belt_Array.keep(statuses, (function (value) {
                                                                            return value !== item;
                                                                          }))
                                                                ) : (
                                                                  checked ? [item] : []
                                                                );
                                                              update({
                                                                    providerName: filters.providerName,
                                                                    statuses: statuses$1,
                                                                    sortBy: filters.sortBy
                                                                  });
                                                            })
                                                        }),
                                                    JsxRuntime.jsx("div", {
                                                          children: ProjectProposal.Status.toString(item),
                                                          className: css.label
                                                        })
                                                  ],
                                                  className: css.dropdownBodyRow
                                                }, String(i));
                                    }))
                            ]
                          })
                    ]
                  }),
              className: css.statusFilter
            });
}

var make = UserProjectProposals_Filter_Status;

export {
  css ,
  status ,
  make ,
}
/* css Not a pure module */
