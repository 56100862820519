// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as RailsContext from "../../../../models/RailsContext.res.js";
import * as ProjectProposal from "../../../../models/ProjectProposal.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectsViewContext from "../../../../models/ProjectsViewContext.res.js";
import * as ProjectStickyBarData from "../_components/ProjectStickyBar/ProjectStickyBarData.res.js";
import * as UserProjectProposals from "./UserProjectProposals.res.js";
import * as UserProjectProposals_Filter_Data from "./UserProjectProposals_Filter_Data.res.js";

function UserProjectProposals__JsBridge$default(props) {
  var railsContext = props.railsContext;
  var viewContext = props.viewContext;
  var projectStatus = props.projectStatus;
  var searchParams = props.searchParams;
  var projectStickyBar = props.projectStickyBar;
  var proposals = props.proposals;
  var railsContext$1 = React.useMemo((function () {
          return RailsContext.fromJson(railsContext);
        }), [railsContext]);
  var proposals$1 = React.useMemo((function () {
          return Belt_Array.map(proposals, ProjectProposal.fromJs);
        }), [proposals]);
  var filters = React.useMemo((function () {
          return UserProjectProposals_Filter_Data.Input.fromJs(searchParams);
        }), [searchParams]);
  var projectStatus$1 = React.useMemo((function () {
          return Project.Status.fromString(projectStatus);
        }), [projectStatus]);
  var userViewContext = React.useMemo((function () {
          return ProjectsViewContext.fromString(viewContext);
        }), [viewContext]);
  var projectStickyBarData = React.useMemo((function () {
          return ProjectStickyBarData.fromJson(projectStickyBar);
        }), [projectStickyBar]);
  return JsxRuntime.jsx(UserProjectProposals.make, {
              proposals: proposals$1,
              filters: filters,
              totalProposals: props.totalProposals,
              currentPage: props.currentPage,
              totalPages: props.totalPages,
              projectId: props.projectId,
              projectTitle: props.projectTitle,
              projectBusinessName: props.projectBusinessName,
              projectStatus: projectStatus$1,
              canSendProposals: props.canSendProposals,
              role: railsContext$1.userRole,
              userViewContext: userViewContext,
              projectStickyBarData: projectStickyBarData,
              desktop: railsContext$1.desktop,
              tablet: railsContext$1.tablet,
              mobile: railsContext$1.mobile
            });
}

var $$default = UserProjectProposals__JsBridge$default;

export {
  $$default as default,
}
/* react Not a pure module */
