// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectHeaderScss from "./ProjectHeader.scss";
import * as ProjectActionButtonsForProvider from "../ProjectActionButtons/ProjectActionButtonsForProvider.res.js";

var css = ProjectHeaderScss;

function ProjectHeader(props) {
  var __className = props.className;
  var projectId = props.projectId;
  var userRole = props.userRole;
  var className = __className !== undefined ? __className : "";
  var tmp;
  if (props.projectStatus === "Active") {
    switch (props.viewContext) {
      case "Personal" :
          switch (userRole) {
            case "Provider" :
            case "User" :
            case "Visitor" :
                tmp = null;
                break;
            default:
              tmp = JsxRuntime.jsx(ProjectActionButtonsForProvider.make, {
                    projectId: projectId,
                    userRole: userRole
                  });
          }
          break;
      case "Admin" :
          switch (userRole) {
            case "User" :
            case "Visitor" :
                tmp = null;
                break;
            default:
              tmp = JsxRuntime.jsx(ProjectActionButtonsForProvider.make, {
                    projectId: projectId,
                    userRole: userRole
                  });
          }
          break;
      case "Visitor" :
          tmp = null;
          break;
      
    }
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h2", {
                      children: props.businessName + (": " + props.title),
                      className: css.projectTitle
                    }),
                tmp
              ],
              className: Cx.cx([
                    css.container,
                    className
                  ])
            });
}

var make = ProjectHeader;

export {
  css ,
  make ,
}
/* css Not a pure module */
