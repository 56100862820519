// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../../../styleguide/components/Paragraph/P.res.js";
import * as Api from "../../../../api/Api.res.js";
import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as TopNavbar from "../../top-navbar/TopNavbar.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconProposal from "../../../../styleguide/icons/IconProposal.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ProjectHeader from "../_components/ProjectHeader/ProjectHeader.res.js";
import * as ProjectNavbar from "../_components/ProjectNavbar/ProjectNavbar.res.js";
import * as AdminTopNavbar from "../../../dashboard/common/admin-top-navbar/AdminTopNavbar.res.js";
import * as ProjectStickyBar from "../_components/ProjectStickyBar/ProjectStickyBar.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ConciergeTopNavbar from "../../../dashboard/common/concierge-top-navbar/ConciergeTopNavbar.res.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.res.js";
import * as UserProjectProposals_Tile from "./UserProjectProposals_Tile.res.js";
import * as UserProjectProposals_Input from "./UserProjectProposals_Input.res.js";
import * as UserProjectProposalsScss from "./UserProjectProposals.scss";
import * as FetchProjectProposalsRequest from "../../../../api/requests/FetchProjectProposalsRequest.res.js";
import * as ProjectActionButtonsForProvider from "../_components/ProjectActionButtons/ProjectActionButtonsForProvider.res.js";
import * as UserProjectProposals_Filter_Status from "./UserProjectProposals_Filter_Status.res.js";
import * as UserProjectProposals_Filter_Sorting from "./UserProjectProposals_Filter_Sorting.res.js";

var css = UserProjectProposalsScss;

function reducer(state, action) {
  switch (action.TAG) {
    case "ToggleOptions" :
        return {
                optionType: action._0,
                proposals: state.proposals,
                filters: state.filters,
                currentPage: state.currentPage,
                totalProposals: state.totalProposals,
                totalPages: state.totalPages,
                projectId: state.projectId,
                projectStatus: state.projectStatus
              };
    case "Paginate" :
        return {
                optionType: state.optionType,
                proposals: state.proposals,
                filters: state.filters,
                currentPage: action._0,
                totalProposals: state.totalProposals,
                totalPages: state.totalPages,
                projectId: state.projectId,
                projectStatus: state.projectStatus
              };
    case "UpdateFilter" :
        return {
                optionType: state.optionType,
                proposals: state.proposals,
                filters: action._0,
                currentPage: 1,
                totalProposals: state.totalProposals,
                totalPages: state.totalPages,
                projectId: state.projectId,
                projectStatus: state.projectStatus
              };
    case "FetchProposals" :
        var res = action._0;
        return {
                optionType: state.optionType,
                proposals: res.proposals,
                filters: state.filters,
                currentPage: state.currentPage,
                totalProposals: res.totalProposals,
                totalPages: res.totalPages,
                projectId: state.projectId,
                projectStatus: state.projectStatus
              };
    case "UpdateProposalStatus" :
        var res$1 = action._0;
        return {
                optionType: state.optionType,
                proposals: Belt_Array.map(state.proposals, (function (item) {
                        if (item.id === res$1.proposal.id) {
                          return res$1.proposal;
                        } else {
                          return item;
                        }
                      })),
                filters: state.filters,
                currentPage: state.currentPage,
                totalProposals: state.totalProposals,
                totalPages: state.totalPages,
                projectId: state.projectId,
                projectStatus: state.projectStatus
              };
    
  }
}

function UserProjectProposals(props) {
  var projectStickyBarData = props.projectStickyBarData;
  var userViewContext = props.userViewContext;
  var role = props.role;
  var projectStatus = props.projectStatus;
  var totalProposals = props.totalProposals;
  var stickyBarUserRole = Project.Role.toRole(projectStickyBarData.userProjectRole);
  var match = React.useReducer(reducer, {
        optionType: "Proposals",
        proposals: props.proposals,
        filters: props.filters,
        currentPage: props.currentPage,
        totalProposals: totalProposals,
        totalPages: props.totalPages,
        projectId: props.projectId,
        projectStatus: projectStatus
      });
  var dispatch = match[1];
  var state = match[0];
  var fetchProposals = function () {
    $$Promise.wait(Api.fetchProjectProposals(state.projectId, state.currentPage, state.filters), (function (x) {
            if (x.TAG === "Ok") {
              return dispatch({
                          TAG: "FetchProposals",
                          _0: x._0
                        });
            } else {
              return SentryLogger.error1({
                          rootModule: "UserProjectProposals",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "UserProjectProposals.make"
                        }, "FetchProposals::Error", [
                          "Error",
                          x._0
                        ]);
            }
          }));
  };
  var updateProposalStatus = function (projectId, proposal, message, param) {
    $$Promise.wait(Api.updateProjectProposalStatus(projectId, proposal, message, undefined), (function (x) {
            if (x.TAG !== "Ok") {
              return SentryLogger.error1({
                          rootModule: "UserProjectProposals",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "UserProjectProposals.make"
                        }, "UpdateStatus::Error", [
                          "Error",
                          x._0
                        ]);
            }
            var match = proposal.status;
            if (match === "Finalized") {
              window.location.reload();
              return ;
            } else {
              return dispatch({
                          TAG: "UpdateProposalStatus",
                          _0: x._0
                        });
            }
          }));
  };
  React.useEffect((function () {
          RescriptReactRouter.push("?" + FetchProjectProposalsRequest.queryString(state.currentPage, state.filters));
          fetchProposals();
        }), [
        state.currentPage,
        state.filters
      ]);
  var tmp;
  switch (userViewContext) {
    case "Personal" :
        tmp = JsxRuntime.jsx(TopNavbar.make, {
              selectedCategory: "Projects"
            });
        break;
    case "Admin" :
        var exit = 0;
        switch (role) {
          case "Admin" :
              tmp = JsxRuntime.jsx(AdminTopNavbar.make, {
                    selectedCategory: "Projects",
                    isAdmin: true,
                    isConcierge: projectStickyBarData.isConcierge,
                    isNotOnlyProviderMember: projectStickyBarData.isNotOnlyProviderMember
                  });
              break;
          case "Provider" :
              tmp = JsxRuntime.jsx(AdminTopNavbar.make, {
                    selectedCategory: "Projects",
                    isAdmin: false,
                    isConcierge: projectStickyBarData.isConcierge,
                    isNotOnlyProviderMember: projectStickyBarData.isNotOnlyProviderMember
                  });
              break;
          case "User" :
          case "Visitor" :
              tmp = null;
              break;
          case "Concierge" :
          case "Agent" :
              exit = 1;
              break;
          
        }
        if (exit === 1) {
          tmp = projectStickyBarData.isConcierge ? JsxRuntime.jsx(ConciergeTopNavbar.make, {
                  selectedCategory: "Projects"
                }) : null;
        }
        break;
    case "Visitor" :
        tmp = null;
        break;
    
  }
  var tmp$1;
  if (state.proposals.length !== 0) {
    tmp$1 = JsxRuntime.jsx(JsxRuntime.Fragment, {
          children: Caml_option.some(Belt_Array.map(state.proposals, (function (proposal) {
                      return JsxRuntime.jsx(UserProjectProposals_Tile.make, {
                                  proposal: proposal,
                                  role: role,
                                  userViewContext: userViewContext,
                                  updateProposalStatus: updateProposalStatus,
                                  projectId: state.projectId
                                }, proposal.id);
                    })))
        });
  } else {
    var tmp$2;
    switch (projectStatus) {
      case "Draft" :
          tmp$2 = JsxRuntime.jsx(P.make, {
                children: "Proposals can't be sent while a project is in Draft mode."
              });
          break;
      case "Active" :
          switch (userViewContext) {
            case "Personal" :
                var exit$1 = 0;
                switch (stickyBarUserRole) {
                  case "Provider" :
                  case "User" :
                      exit$1 = 1;
                      break;
                  case "Visitor" :
                      tmp$2 = null;
                      break;
                  default:
                    tmp$2 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                          children: [
                            JsxRuntime.jsx(P.make, {
                                  children: "No proposals have been sent."
                                }),
                            JsxRuntime.jsx(ProjectActionButtonsForProvider.make, {
                                  projectId: state.projectId,
                                  userRole: stickyBarUserRole
                                })
                          ]
                        });
                }
                if (exit$1 === 1) {
                  tmp$2 = JsxRuntime.jsx(P.make, {
                        children: "No proposals found."
                      });
                }
                break;
            case "Admin" :
                tmp$2 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                      children: [
                        JsxRuntime.jsx(P.make, {
                              children: "No proposals have been sent."
                            }),
                        JsxRuntime.jsx(ProjectActionButtonsForProvider.make, {
                              projectId: state.projectId,
                              userRole: stickyBarUserRole
                            })
                      ]
                    });
                break;
            case "Visitor" :
                tmp$2 = null;
                break;
            
          }
          break;
      case "Inactive" :
          tmp$2 = JsxRuntime.jsx(P.make, {
                children: "Proposals can't be sent while a project is Inactive."
              });
          break;
      case "Finalized" :
          tmp$2 = JsxRuntime.jsx(P.make, {
                children: "Proposals can't be sent after a project is Finalized."
              });
          break;
      case "Review" :
          tmp$2 = JsxRuntime.jsx(P.make, {
                children: "Proposals can't be sent while a project is In Review."
              });
          break;
      
    }
    tmp$1 = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx(IconProposal.make, {
                  size: "XXXXXL",
                  color: "Teal"
                }),
            tmp$2
          ],
          className: css.emptyProposals
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(ProjectHeader.make, {
                                    title: props.projectTitle,
                                    businessName: props.projectBusinessName,
                                    userRole: stickyBarUserRole,
                                    projectId: state.projectId,
                                    viewContext: userViewContext,
                                    projectStatus: projectStickyBarData.project.status
                                  }),
                              JsxRuntime.jsx(ProjectNavbar.make, {
                                    activeTab: "Proposals",
                                    userRole: stickyBarUserRole,
                                    projectId: state.projectId,
                                    totalProposals: totalProposals,
                                    viewContext: userViewContext
                                  })
                            ],
                            className: css.contentWrapper
                          }),
                      className: css.barHeading
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(UserProjectProposals_Input.make, {
                                              filters: state.filters,
                                              update: (function (filters) {
                                                  dispatch({
                                                        TAG: "UpdateFilter",
                                                        _0: filters
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx(UserProjectProposals_Filter_Status.make, {
                                              filters: state.filters,
                                              update: (function (filters) {
                                                  dispatch({
                                                        TAG: "UpdateFilter",
                                                        _0: filters
                                                      });
                                                })
                                            })
                                      ],
                                      className: css.filters
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: String(state.totalProposals) + " Proposals",
                                              className: css.proposalsCount
                                            }),
                                        JsxRuntime.jsx(UserProjectProposals_Filter_Sorting.make, {
                                              filters: state.filters,
                                              update: (function (filters) {
                                                  dispatch({
                                                        TAG: "UpdateFilter",
                                                        _0: filters
                                                      });
                                                })
                                            })
                                      ],
                                      className: css.proposalsCountSortPair
                                    })
                              ],
                              className: css.proposalsList
                            }),
                        tmp$1,
                        JsxRuntime.jsx("div", {
                              children: state.totalPages > 1 ? JsxRuntime.jsx(Pagination.make, {
                                      currentPage: state.currentPage,
                                      totalPages: state.totalPages,
                                      onPageClick: (function (pageNum) {
                                          dispatch({
                                                TAG: "Paginate",
                                                _0: pageNum
                                              });
                                        })
                                    }) : null,
                              className: css.paginationBar
                            })
                      ],
                      className: css.contentWrapper
                    }),
                JsxRuntime.jsx(ProjectStickyBar.make, {
                      userRole: stickyBarUserRole,
                      projectStickyBarData: projectStickyBarData,
                      canSendProposals: props.canSendProposals,
                      viewContext: userViewContext,
                      desktop: props.desktop,
                      tablet: props.tablet,
                      mobile: props.mobile
                    })
              ],
              className: css.container
            });
}

var make = UserProjectProposals;

export {
  css ,
  reducer ,
  make ,
}
/* css Not a pure module */
